<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12" md="5">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search"
              @input="getItems"
              class="d-inline-block mr-1"
              placeholder="Busquedad de Productos"
            />
          </div>
        </b-col>

        <b-col cols="12" md="7" v-if="isAdmin || isCashier">
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="$router.push({ name: 'products-create' })"
              class="d-flex align-items-center"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="text-nowrap">Nuevo</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <div class="mx-auto p-1"></div>

      <b-overlay
        :show="loading"
        rounded
        responsive
        variant="transparent"
        opacity="0.33"
        blur="2px"
        class="table-responsive"
      >
        <b-table
          hover
          :items="items.data"
          primary-key="id"
          :fields="fields"
          show-empty
          empty-text="No hay productos registrados"
        >
          <template #cell(price)="data">
            {{ data.item.price | currency }}
          </template>

          <template #cell(discount)="data">
            <span v-if="data.item.discount">
              {{ data.item.discount | currency({ symbol: "", precision: 2 })
              }}{{ data.item.discount_type == "percent" ? "%" : "" }}
            </span>
          </template>

          <template #cell(stock_alert_min)="data">
            {{ data.item.stock_alert_min }}
          </template>

          <template #cell(stock_alert_max)="data">
            {{ data.item.stock_alert_max }}
          </template>

          <template #cell(status)="data">
            <span>{{ data.item.status == 1 ? "Activo" : "Inactivo" }}</span>
          </template>

          <template #cell(image)="data">
            <img :src="data.item.image" height="100" width="100" />
          </template>

          <template #cell(updated_at)="data">
            {{ data.item.updated_at | formatDateTimeHumane }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="
                  $router.push({
                    name: 'products-edit',
                    params: {
                      id: data.item.id,
                      page_preview: items.current_page,
                    },
                  })
                "
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="isAdmin || isCashier"
                @click="deleteMetho(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="items.current_page"
              :total-rows="items.total"
              :per-page="items.per_page"
              first-number
              @change="getItems"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BLink,
  BPagination,
} from "bootstrap-vue";

import store from "@/store";
import { formatDateTimeHumane } from "@core/utils/filter.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BAvatar,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BLink,
    BPagination,
  },
  filters: {
    formatDateTimeHumane,
  },
  data() {
    return {
      loading: false,
      search: null,
      items: {},
      searchTimeout: null,
      page: 1,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    fields() {
      let data = [
        {
          key: "school.name",
          label: "Colegio",
          sortable: true,
        },
        {
          key: "name",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
        },
        {
          key: "status",
          label: "Estatus",
          sortable: true,
        },
        {
          key: "image",
          label: "Image",
          sortable: true,
        },
        {
          key: "price",
          label: "Precio",
          sortable: true,
        },
        {
          key: "discount",
          label: "Descuento",
          sortable: true,
        },
        {
          key: "stock",
          label: "Stock",
          sortable: true,
        },
        {
          key: "stock_alert_min",
          label: "Stock Minimo",
          sortable: true,
        },
        {
          key: "stock_alert_max",
          label: "Stock Maximo",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Actualizado",
          sortable: true,
        },
      ];

      if (this.isAdmin || this.isCashier) {
        data.push({ key: "actions", label: "Acciones" });
      }

      return data;
    },
  },
  methods: {
    async deleteMetho({ id, name }) {
      try {
        const res = await this.$bvModal.msgBoxConfirm(
          `Estas seguro de borrar la Producto ${name}?`,
          {
            title: "Por favor confirmar",
            size: "sm",
            okVariant: "primary",
            okTitle: "Si",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (res) {
          await store.dispatch("products/delete", { id: id });
          this.getItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Producto eliminada correctamente",
            },
          });
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "No se pudo eliminar la Producto.",
          },
        });
      }
    },
    async getItems() {
      try {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(async () => {
          this.loading = true;
          this.items = await store.dispatch("products/index", {
            page: this.items.current_page,
            search: this.search,
          });
          this.loading = false;
        }, 500); // 500ms de retraso
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.getItems(this.$router.currentRoute.params.page_preview);
  },
  created() {
    this.getItems(this.$router.currentRoute.params.page_preview);
  },
};
</script>

<style>
.d-contens {
  display: contents;
}
</style>
